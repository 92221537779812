.con-icon {
  width: 100%;
  height: 900px;
}
.config-navbar {
  width: 100%;
  height: 80px;
  background: rgb(255, 249, 249);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: top 0.3s;
}

.con-icon {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.con-icon img {
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.con-link {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.main-link {
  text-decoration: none;
  color: rgb(42, 42, 42);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.main-link.active {
  color: #00d674;
}

.main-link:active {
  color: rgba(0, 214, 116, 1);
}

.headPage {
  width: 100%;
  height: 900px;
  background-image: url("../../public/mainHome/back2.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
