.contact-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.contact-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-subtitle {
  margin-bottom: 20px;
}

.contact-description {
  margin-bottom: 30px;
}

.attachment-section {
  margin-bottom: 40px;
}

.attachment-title {
  font-weight: bold;
}

.attachment-box {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin-top: 10px;
}

.attachment-instruction {
  color: #999;
}

.contact-form-section {
  display: flex;
  flex-direction: column;
}

.section-title {
  font-weight: bold;
  margin-top: 20px;
}

.contact-input {
  margin-bottom: 20px;
}

.contact-checkbox {
  margin: 20px 0;
}

.submit-button {
  width: 100%;
  padding: 15px 0;
  font-size: 16px;
}

.contact-footer {
  margin-top: 40px;
  text-align: center;
}

.footer-text {
  color: #999;
}
