.container {
  margin-top: 20px;
}

.table-container {
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.table-container:hover {
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.table {
  min-width: 650px;
}

.table-head {
  background-color: #f5f5f5;
}

.table-cell-head {
  font-weight: bold;
  color: #333;
}

.table-row:nth-of-type(odd) {
  background-color: #fafafa;
}

.table-cell {
  color: #555;
}

.detail-cell {
  padding: 0 !important;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.table-row {
  cursor: pointer; /* Add cursor pointer on hover */
}
