.img-config {
  width: 100%;
  height: 1500px;
  /* background-image: url("../../public/Icons/half.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%; */

  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainImg {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("../../public/Icons/half2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom; */

  margin-top: 30px;
}

.tabButton {
  height: 320px; /* Set fixed height */
  width: 270px;
  font-size: 20px;
  font-weight: 600;
  background-image: url("../../public/best/2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 15px;
  color: white; /* Ensure the text color is white for visibility */
}

.tabButton::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s;
  z-index: 1;
}

.tabButton:hover::after {
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay on hover */
}

.tabButton > div {
  position: relative;
  z-index: 2;
}

.tabButton span {
  font-size: 22px;
  font-weight: 600;
  color: rgb(127, 255, 255);
}

.mySwiper {
  width: 955px;
  height: 531px;
  overflow: hidden;
}

.mySwiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent overflow */
}

.mySwiper .swiper-slide img {
  width: 100%;
  height: auto;
  display: block;
}

.mySwiper .swiper-pagination-bullet {
  background: rgba(255, 255, 255, 1); /* Default bullet color */
  width: 10px;
  height: 10px;
  margin: 0 4px;
  border-radius: 50%;
  opacity: 1 !important;
}

.mySwiper .swiper-pagination-bullet-active {
  background: rgb(86, 86, 255);
  width: 20px;
  height: 10px;
  border-radius: 10px;
}

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 75px;
  height: 75px;
  margin-top: -25px;
  opacity: 1 !important;
}

/* .mySwiper .swiper-button-next {
  background-image: url("../../public/mainHome/right2.png");
}

.mySwiper .swiper-button-prev {
  background-image: url("../../public/mainHome/left2.png");
} */

.mySwiper .swiper-button-next::after,
.mySwiper .swiper-button-prev::after {
  color: white;
}

/* Product head  */

#product-head {
  width: 100%;
  height: 600px;

  background-image: url("../../public/mainHome/back.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.productBtn {
  width: 81%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
}
.productWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.tabButton {
  flex: 1 1 calc(25% - 40px); /* Adjust 25% width minus the total gap on both sides */
  height: 320px; /* Adjust height as needed */
  margin: 10px; /* Margin to create space around each button */
}

@media (max-width: 1024px) {
  .tabButton {
    flex: 1 1 calc(33.33% - 40px); /* Three buttons per row on medium screens */
  }
}

@media (max-width: 768px) {
  .tabButton {
    flex: 1 1 calc(50% - 40px); /* Two buttons per row on small screens */
  }
}

@media (max-width: 480px) {
  .tabButton {
    flex: 1 1 100%; /* One button per row on very small screens */
  }
}
