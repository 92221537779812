.footer {
  background-color: #f8f9fa;
  padding: 40px 0;
  margin-top: auto;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.footer-column {
  flex: 1;
  min-width: 200px;
}

.footer-column h6 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-column p,
.footer-column ul,
.footer-column li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: #555;
}

.footer-column ul {
  padding: 0;
}

.footer-column li {
  margin-bottom: 5px;
}

.footer-column a {
  color: #555;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-column a:hover {
  color: #007bff;
}

.footer-bottom {
  margin-top: 20px;
  text-align: center;
  color: #777;
}

@media (max-width: 768px) {
  .footer-grid {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
